import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
// import TestHistory from './TestHistory';
// import Summary from './Summary';
import QuickLinks from './QuickLinks';
import RecentAnnouncement from './RecentAnnouncement';
import { useModuleContext } from '../../context/ModuleProvider';
import $ from 'jquery';
// import { isIOS } from '../helper';
import './Dashboard.css';
import { AuthContext } from '../../context/AuthContextProvider';
import Preloader from '../Preloader/Preloader';
import RecentNotification from './RecentNotification';
import notice from '../../../public/images/notice.webp';
import PopupModal from './PopupModal';

function Dashboard() {
  let { moduleId, url } = useModuleContext();
  let isMobile = window.innerWidth < 767;

  const [subjects, setSubjects] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/practice`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      let subjects = res.data.subjects;
      subjects && setSubjects(subjects);
    });
  }, [url]);

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/teacher/teacher?limit=1000`,
      withCredentials: true,
    }).then((res) => {
      setLoading(false);
      let instructors = res.data.data;
      instructors && setInstructors(instructors);
    });
  }, [url]);

  useEffect(() => {
    $('.dropdown-el').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).toggleClass('expanded');
      $('#' + $(e.target).attr('for')).prop('checked', true);
    });
    $(document).click(function () {
      $('.dropdown-el').removeClass('expanded');
    });
  }, []);

  let recentVideos = JSON.parse(localStorage.getItem('videoList'));
  let { firstLogin, setFirstLogin } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(firstLogin);

  return !loading ? (
    <div className="w-100" id="dash__wrapper">
      <div className="dashboard__container">
        <div className="main_dash">
          {openModal && <PopupModal closeModal={setOpenModal} moduleId={moduleId} setFirstLogin={setFirstLogin} />}
          <div className="row dash__header">
            <div className="col-12 col-md-8 px-0">
              <Welcome url={url} moduleId={moduleId} />

              {/* <div className='bg-white p-2 mt-2'>
                {moduleId === "NMCLE" && <img src={notice} alt="notice" />}
              </div> */}

              {/* <div className="dash__recents p-0 px-2 pt-2 p-md-3">
                <div className="instructor_header">
                  <h6 className="font-weight-bold p-1">Subjects</h6>
                  <Link to={`/PG/practice`} className="link-viewall">
                    View All
                  </Link>
                </div>
                {subjects.length ? (
                  isMobile ? (
                    <swiper-container class="mySwiper" pagination="true" pagination-dynamic-bullets="true">
                      {subjects
                        .reduce((accumulator, currentValue, currentIndex, array) => {
                          if (currentIndex % 2 === 0) {
                            accumulator.push(array.slice(currentIndex, currentIndex + 2));
                          }
                          return accumulator;
                        }, [])
                        .map((subject, i) => {
                          return (
                            <swiper-slide key={i} class="mb-4 p-1  subject_slide sub-overflow">
                              <SubjectCard subject={subject[0]} />
                              {subject[1] && <SubjectCard subject={subject[1]} />}
                            </swiper-slide>
                          );
                        })}
                    </swiper-container>
                  ) : (
                    <div className="d-flex subject_slide sub-overflow">
                      {subjects.map((subject) => (
                        <SubjectCard subject={subject} key={subject.id} />
                      ))}
                    </div>
                  )
                ) : (
                  <div className="no-subjects">No subjects listed yet</div>
                )}
              </div> */}

              {/* {recentVideos ? (
                <div className="dash__recents p-0 px-2 pt-2 p-md-3 mt-2 mb-2">
                  <h6 className="font-weight-bold p-1">Recently viewed</h6>
                  {isMobile ? (
                    <swiper-container class="mySwiper" pagination="true" pagination-dynamic-bullets="true">
                      {recentVideos[1] && (
                        <swiper-slide class="mb-4 p-1">
                          <Recents
                            id={recentVideos[1].id}
                            videoTitle={recentVideos[1].title}
                            chapter={recentVideos[1].chapter.chapter_name}
                          />
                        </swiper-slide>
                      )}
                      <swiper-slide class="mb-4 p-1">
                        <Recents
                          id={recentVideos[0].id}
                          videoTitle={recentVideos[0].title}
                          chapter={recentVideos[0].chapter.chapter_name}
                        />
                      </swiper-slide>
                    </swiper-container>
                  ) : (
                    <div className="lg_recents">
                      {recentVideos[1] && (
                        <Recents
                          id={recentVideos[1].id}
                          videoTitle={recentVideos[1].title}
                          chapter={recentVideos[1].chapter.chapter_name}
                        />
                      )}
                      <Recents
                        id={recentVideos[0].id}
                        videoTitle={recentVideos[0].title}
                        chapter={recentVideos[0].chapter.chapter_name}
                      />
                      {!recentVideos[1] && (
                        <Recents videoTitle="Your recent videos appear here" chapter="Watch other videos and lessons" />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="dash__recents p-0 px-2 pt-2 p-md-3 mt-2 mb-2">
                  <h6 className="font-weight-bold p-1">Recently viewed</h6>
                  <div className="lg_recents">
                    <Recents videoTitle="Your recent videos appear here" chapter="Watch other videos and lessons" />
                  </div>
                </div>
              )} */}

              {/* <div className="dash__recents p-0 px-2 pt-2 p-md-3">
                <div className="instructor_header">
                  <h6 className="font-weight-bold p-1">Our Instructors</h6>
                  <Link to={`/${moduleId}/instructors/all`} className="link-viewall">
                    View All
                  </Link>
                </div>

                {instructors.length ? (
                  isMobile ? (
                    <swiper-container class="mySwiper" pagination="true" pagination-dynamic-bullets="true">
                      {instructors
                        .reduce((accumulator, currentValue, currentIndex, array) => {
                          if (currentIndex % 2 === 0) {
                            accumulator.push(array.slice(currentIndex, currentIndex + 2));
                          }
                          return accumulator;
                        }, [])
                        .map((instructor, i) => {
                          return (
                            <swiper-slide key={i} class="mb-4 p-1 subject_slide">
                              <InstructorCard instructor={instructor[0]} />
                              {instructor[1] && <InstructorCard instructor={instructor[1]} />}
                            </swiper-slide>
                          );
                        })}
                    </swiper-container>
                  ) : (
                    <div className="d-flex subject_slide sub-overflow">
                      {instructors.map((instructor) => {
                        return <InstructorCard instructor={instructor} key={instructor.id} />;
                      })}
                    </div>
                  )
                ) : (
                  <div className="no-instructors">No instructors listed yet</div>
                )}
              </div> */}
              {/* <TestUpdate url={url} moduleId={moduleId} /> */}
              <RecentAnnouncement />
              <TestUpdate url={url} moduleId={moduleId} />
              {/* <RecentNotification /> */}

              {/* Notice  */}
              {/* <QuickLinks url={url} moduleId={moduleId} /> */}

              {/* <TestUpdate url={url} moduleId={moduleId} /> */}
              {/* <div className="row mt-2">
                <div className="col-md-12 px-sm-0 pb-sm-2 px-1">
                  <TestHistory url={url} moduleId={moduleId} />
                </div>
              </div> */}
            </div>
            <div className="col-md-4 col-12 px-0 px-md-0 mt-1">
              <div className="dash__stats">
                <div className="row">
                  {/* Test Summary */}
                  {/* <div className="p-0"> */}
                  {/* <Summary url={url} moduleId={moduleId} /> */}
                  {/* </div> */}
                  {/* CountDown */}
                  {/* <CountDown /> */}
                  {/* <TestUpdate url={url} moduleId={moduleId} /> */}
                  {/* <div className='bg-white p-2 mx-2 my-2 dash__border'>
                    {moduleId === "MBBS" && <img src={notice} alt="notice" className='dash__border' style={{ width: "100%" }} />}
                  </div> */}
                  <RecentNotification />
                  <div className="col-11 bg-white p-2 mx-2 my-2 dash__border">
                    {moduleId === 'MBBS' && (
                      <img
                        src={notice}
                        alt="notice"
                        className="dash__border"
                        style={{ width: '100%', cursor: 'pointer' }}
                        onClick={() => setOpenModal(true)}
                      />
                    )}
                  </div>
                  {/* <RecentAnnouncement />
                  <RecentNotification />

                  {/* Notice  */}
                  {/* <QuickLinks url={url} moduleId={moduleId} /> */}
                </div>
              </div>
              {/* Download Bochures */}
              {moduleId === 'nursing' && (
                <div className="dash__stats">
                  <div className="row">
                    <div className="col-md-12 col-12 mb-2 pl-md-2 px-0">
                      <div className="bg-white p-3 chart_contain dash__shadow dash__border">
                        <h6 style={{ fontWeight: 600 }}>Download Brochures</h6>
                        <img src="/images/dashboard/downloader.jpg" alt="e-book download" />
                        <div className="no-img">
                          <ul className="products-list product-list-in-card ">
                            <li className="item">
                              <div className="product-img">
                                <img className="img-circle" src="/images/dashboard/e-book.jpg" alt="E-book" />
                              </div>
                              <div className="product-info">
                                <a
                                  href={`/download/NameProspectus.pdf`}
                                  className="product-title"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                >
                                  Name Nursing Prospectus
                                  <br />
                                  <p className="text-muted notice-desc">Click to download</p>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Preloader />
  );
}

// function CountDown() {
//   const [activeDate, setActiveDate] = useState(false);
//   const [examOver, isExamOver] = useState(false);
//   let dates = [
//     {
//       id: 'cee',
//       name: 'CEE (Common Entrance Exam)',
//       date: '2022/03/15',
//     },
//   ];

//   const handleChange = (e) => {
//     let value = e.target.value;
//     dates.map((d) => {
//       if (d.id === value) {
//         setActiveDate(d.date);
//         return 1;
//       }
//       return 1;
//     });
//   };

//   const getDateString = (date) => {
//     if (date) {
//       let currentDate = moment.utc();
//       let examDate = moment.utc(date, 'YYYY/MM/DD').diff(currentDate);
//       let durationLeft = moment.utc(examDate).format('DDD');
//       return [...durationLeft.split('')];
//     } else {
//       return [];
//     }
//   };

//   useEffect(() => {
//     if (moment.utc(dates[0].date).diff(moment.utc()) > 0) {
//       setActiveDate(dates[0].date);
//     } else {
//       isExamOver(true);
//     }
//   }, []);

//   let dateString = getDateString(activeDate);

//   return (
//     <div className="col-md-12 col-12 mb-2 pl-md-2 px-0" style={{ marginTop: '5px' }}>
//       <div className="bg-white p-3 chart_contain dash__shadow dash__border">
//         <h6 style={{ fontWeight: 600 }}>CEE (Common Entrance Exam)</h6>
//         {examOver ? (
//           <div>
//             CEE exam has already ended for this fiscal year. Please study tuned, we shall post the date as soon as its
//             available.
//           </div>
//         ) : (
//           <div>
//             <div className="countdown">
//               {dateString.map((s, index) => (
//                 <div key={index} className="countdown__digit">
//                   {s}
//                 </div>
//               ))}
//             </div>
//             <div className="countdown__message">days until examination(approx)</div>
//           </div>
//         )}

//         <img src="/images/alram.png" alt="alarm" />
//       </div>
//     </div>
//   );
// }

function TestUpdate({ url, moduleId }) {
  let [test, setTest] = useState(null);
  let redirect = '';
  let text = '';
  let header = '';
  let title = '';
  let query = test && `testId=${test.id}`;
  let testPaymentLink = `/${moduleId}/test-payment`;
  let startTestLink = `/${moduleId}/test/instruction`;
  let disabled = false;

  if (test) {
    let startDate = moment.utc(test.start);
    let endDate = moment.utc(test.end);
    let currentDate = moment.utc();
    if (startDate <= currentDate && endDate >= currentDate) {
      if (test.done) {
        redirect = `/${moduleId}/dashboard`;
        text = 'Submitted';
        header = `Expires ${endDate.local().fromNow()}`;
        query = '';
        title = "Today's Test";
        disabled = true;
      } else {
        redirect = test.price ? (test.hasPaid ? startTestLink : testPaymentLink) : startTestLink;
        text = test.price ? (test.hasPaid ? 'Start' : 'Purchase Test') : 'Start';
        header = `Exam will end ${endDate.fromNow()}`;
        title = "Today's Test";
      }
    } else if (startDate > currentDate) {
      redirect = test.price ? (test.hasPaid ? `/${moduleId}/test` : `/${moduleId}/test-payment`) : `/${moduleId}/test`;
      query = test.price ? (test.hasPaid ? '' : `testId=${test.id}`) : '';
      if (startDate < currentDate.add(12, 'hour')) {
        header = `Exam will start ${startDate.fromNow()}`;
      } else {
        header = `Exam will start ${startDate.fromNow()}`;
      }
      text = test.price ? (test.hasPaid ? 'Check Test' : 'Purchase Test') : 'Check Test';
      title = 'Upcoming Test';
    } else if (endDate < currentDate) {
      if (test.done) {
        redirect = `/${moduleId}/test/result`;
        header = 'Ended';
        text = 'View Result';
        title = 'Test Result';
      } else {
        query = '';
        header = "Result Unavailable ! You didn't attend this test.";
        text = 'Test not taken';
        disabled = true;
        title = 'Test Result';
      }
    }
  }
  let testTime = moment(test && test.start).format('DD MMM');

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/test/recent`,
      withCredentials: true,
    }).then(async (res) => {
      setTest(res.data.test);
    });
  }, []);

  return test ? (
    <div className="col-12 pl-md-2">
      <div className="col-md-12 px-0">
        <div className="bg-white p-3 chart_contain dash__shadow dash__border  dash__border">
          {test && (
            <>
              <h6 style={{ fontWeight: 600 }}>{title}</h6>
              <hr style={{ marginTop: '5px', marginBottom: '-5px' }} />
              <br />
              <div className="row d-flex align-items-center">
                <div className="col-md-12 pl-2 mb-3">
                  <div className="row">
                    <div className="col-auto pl-0 pr-0">
                      <div
                        className="info-box-icon bg-danger"
                        style={{
                          width: '60px',
                          fontSize: '13px',
                          padding: '8px',
                          height: '60px',
                        }}
                      >
                        NOW
                        <br />
                        {testTime}
                      </div>
                    </div>
                    <div
                      className="col-lg-9 col-sm-9 col-md-9 col-8 d-flex align-items-center"
                      style={{ paddingLeft: '10px' }}
                    >
                      <div style={{ borderLeft: '2px solid var(--light)' }}>
                        <div style={{ paddingLeft: '10px' }}>
                          {test.name}
                          <br />
                          {test && (
                            <div>
                              {moment(test.start).format('D MMMM,YYYY')}
                              {' | '}
                              {moment(test.start).format('h:mma')}
                            </div>
                          )}
                          <p style={{ fontSize: '13px' }}>
                            {test.total} Questions, {test.duration} mins | {header}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pl-2 pr-2 text-left mob-start">
                  <div>
                    <Link
                      to={{
                        pathname: redirect,
                        search: query && `?${query}`,
                        state: {
                          id: test.id,
                          name: test.name,
                          price: test.price,
                        },
                      }}
                    >
                      <button className="btn btn-dark" style={{ minWidth: '20px', width: '40%' }} disabled={disabled}>
                        {text}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    ''
  );
}

function Welcome({ url, moduleId }) {
  let { currentUser } = useContext(AuthContext);
  const [greet, setGreet] = useState('');

  const [payment, setPayment] = useState();

  useEffect(() => {
    axios({
      method: 'get',
      url: `${url}/payment/last`,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        setPayment(res.data.payment);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const currentTime = new Date().getHours();
    let greetingWord = '';

    if (currentTime >= 3 && currentTime < 12) {
      greetingWord = 'Morning';
    } else if (currentTime >= 12 && currentTime < 17) {
      greetingWord = 'Afternoon';
    } else if (currentTime >= 17 && currentTime < 22) {
      greetingWord = 'Evening';
    } else {
      greetingWord = 'Night';
    }

    setGreet(greetingWord);
  }, []);

  const expired = moment(payment && payment.expire_date).diff(moment()) < 0;

  return (
    <div>
      <div className="dash__welcome mb-2">
        <div className={`greeting_card ${greet}`}>
          <h4>
            Good {greet}, {currentUser.name}!
          </h4>
          <p>Let's lead you to soar, unleash your learning potential.</p>
        </div>
      </div>

      {(!payment || expired) && (
        <div className="row-12 dash__subs mb-2 p-1 mt-2">
          <div className="row-12 d-md-flex">
            <div className="col-md-8 p-3 d-flex">
              <div className="col-xs-2 col-md-1  d-flex flex-column justify-content-center p-0">
                <img src="/images/star.png" alt="Star icon" width="50px" />
              </div>
              <div className="col-10 d-flex flex-column justify-content-center ml-2">
                <div>
                  <h6 className="font-weight-bold mb-0">Introducing best Subscription Plans!</h6>
                  <br />
                  <p className="mt-xs-2 mt-0">
                    Take Your {moduleId} Preparation to Next Level & Discover more today !!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 bg-white d-flex flex-column justify-content-center">
              <Link
                to={`/${moduleId}/payment/`}
                className="btn btn-primary py-2 w-100 radius-5 mt-1 mt-sm-0"
                style={{}}
              >
                <h5 className="mb-0 pr-0">Purchase Now</h5>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function Recents({ id, videoTitle, chapter }) {
  let { moduleId } = useModuleContext();

  return (
    <div className="recent_videos_card">
      <Link to={id ? `/${moduleId}/video/single?id=${id}` : '#'}>
        <div className="details d-flex flex-row justify-content-start align-items-center">
          <div className="play d-flex">
            <div className="icon">
              <i className="fas fa-play-circle"></i>
            </div>
          </div>
          <div className="video_details">
            <h5 className="mb-0">{videoTitle}</h5>
            <br />
            <h6 className="font-weight-normal">{chapter}</h6>
          </div>
        </div>
      </Link>
    </div>
  );
}

function SubjectCard({ subject }) {
  let server = process.env.REACT_APP_SERVER_URL;
  let link = `/${subject.module_id}/practice/unitDetail?subjectId=${subject.subject_id}`;
  const [imgErrorCount, setImgErrorCount] = useState(0);

  return (
    <>
      <div className="subject_card w-50">
        <Link to={link}>
          <div className="details d-flex flex-column justify-content-center align-items-center">
            <img
              src={`${server}/images/subjectLight/icons/${subject.module_id}/${subject.subject_name.toLowerCase()}.png`}
              className="responsive-img"
              alt={subject.subject_name}
              width={'60px'}
              onError={({ currentTarget }) => {
                if (imgErrorCount < 4) {
                  currentTarget.onerror = null;
                  currentTarget.src = `/images/defaultSubject.png`;
                  setImgErrorCount((pre) => pre + 1);
                }
              }}
            />
            <h6 className="mb-0">{subject.subject_name}</h6>
          </div>
        </Link>
      </div>
    </>
  );
}
export function InstructorCard({ instructor }) {
  let server = process.env.REACT_APP_SERVER_URL;
  const [imgErrorCount, setImgErrorCount] = useState(0);

  return (
    <>
      <div className="instructor_card instructor w-50">
        <div className="details d-flex flex-column justify-content-center align-items-center mb-1">
          <img
            src={`${server}/images/instructor/${instructor.image_id}`}
            className="instructor-img"
            alt={instructor.name}
            onError={({ currentTarget }) => {
              if (imgErrorCount < 4) {
                currentTarget.onerror = null;
                currentTarget.src = `/images/instructor.png`;
                setImgErrorCount((pre) => pre + 1);
              }
            }}
          />
        </div>
        <h6 className="mb-0">{`${instructor.first_name} ${instructor.middle_name} ${instructor.last_name} - ${instructor.subject_name}`}</h6>
      </div>
    </>
  );
}

export default withRouter(Dashboard);
